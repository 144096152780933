import axios from 'axios'

import { LOGOUT_RESOURCE } from './use-service'

import { useAuthStore } from '../store/auth-store'

const BASE_URL = import.meta.env.VITE_API_BASE

export const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
})

export const authenticatedApi = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
})
authenticatedApi.interceptors.response.use(
  (response: any) => response,
  async (error: any) => {
    if (error.response?.status === 401) {
      const { logout } = useAuthStore.getState()
      try {
        await api.get(`/${LOGOUT_RESOURCE}`)
      } catch (logoutError) {
        console.warn('Logout failed:', logoutError)
      }
      logout('Your session has expired. Please log in again to continue.')
    }
    return Promise.reject(error)
  },
)
