import { Spin } from 'antd'
import React, { lazy, Suspense, FC, useCallback, useEffect, useRef } from 'react'
import { BrowserRouter, Routes, Route, Navigate, Outlet, useNavigate } from 'react-router-dom'

import { useAuthStore } from './store/auth-store'
import { useAuthService } from './api/use-service'

const AuthLayout = lazy(() => import('@/layout/AuthLayout'))
const MainLayout = lazy(() => import('@/layout/MainLayout'))
const LoginPage = lazy(() => import('./pages/LoginPage'))
const RegisterPage = lazy(() => import('./pages/RegisterPage'))
const ForgotPage = lazy(() => import('./pages/ForgotPage'))
const RecoveryPage = lazy(() => import('./pages/RecoveryPage'))
const VerificationPage = lazy(() => import('./pages/VerificationPage'))
const CallbackPage = lazy(() => import('./pages/CallbackPage'))
const HomePage = lazy(() => import('./pages/HomePage'))
const ChatPage = lazy(() => import('./pages/ChatPage'))

export enum routes {
  HOME = '/home',
  LOGIN = '/signin',
  REGISTER = '/signup',
  FORGOT = '/forgot-password',
  RECOVERY = '/password-recovery',
  VERIFICATION = '/verification',
  AUTH_CALLBACK = '/callback',
  CHAT_TEST = '/chat-test',
}

export const onboardingRoutes = [
  { path: routes.LOGIN, label: 'Login', element: LoginPage },
  { path: routes.REGISTER, label: 'Register', element: RegisterPage },
  { path: routes.FORGOT, label: 'Forgot Password', element: ForgotPage },
  { path: routes.RECOVERY, label: 'Password Recovery', element: RecoveryPage },
  { path: routes.VERIFICATION, label: 'Verification', element: VerificationPage },
  { path: routes.AUTH_CALLBACK, label: 'Auth Callback', element: CallbackPage },
]
export const mainRoutes = [
  { path: routes.HOME, label: 'Home', element: HomePage },
  { path: routes.CHAT_TEST, label: 'Chat Test', element: ChatPage },
]

const RequireAuth: FC<{ children: JSX.Element; isLogged: boolean; isLoading: boolean }> = ({
  children,
  isLogged,
  isLoading,
}) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (!isLogged) {
      navigate({ pathname: routes.LOGIN })
    }
  }, [isLogged, navigate])

  if (isLoading) {
    return <Spin className="flex h-full w-full items-center justify-center" />
  }

  return children
}

const RequireNonAuth: FC<{ children: JSX.Element; isLogged: boolean; isLoading: boolean }> = ({
  children,
  isLogged,
  isLoading,
}) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (isLogged) {
      navigate({ pathname: routes.HOME })
    }
  }, [isLogged, navigate])

  if (isLoading) {
    return <Spin className="flex h-full w-full items-center justify-center" />
  }

  return children
}

export const Router: FC = () => {
  const { isLogged, login } = useAuthStore()
  const { checkAuthentication } = useAuthService()
  const initialized = useRef(false)

  const checkAuth = useCallback(async () => {
    const { data, error } = await checkAuthentication.invoke()
    if (!error) {
      login(data?.id, data?.email, data?.name)
    }
    return data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkAuthentication.invoke])

  useEffect(() => {
    try {
      checkAuth()
    } finally {
      initialized.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (checkAuthentication.isLoading || !initialized.current) {
    return <Spin className="flex h-full w-full items-center justify-center" />
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to={isLogged ? routes.HOME : routes.LOGIN} replace />} />
        <Route
          element={
            <RequireNonAuth isLogged={isLogged} isLoading={checkAuthentication.isLoading}>
              <Suspense fallback={<Spin className="flex h-full w-full items-center justify-center" />}>
                <AuthLayout children={<Outlet />} />
              </Suspense>
            </RequireNonAuth>
          }
        >
          {onboardingRoutes.map(({ path, element: Component }) => (
            <Route key={path} path={path} element={<Component />} />
          ))}
        </Route>
        <Route
          element={
            <RequireAuth isLogged={isLogged} isLoading={checkAuthentication.isLoading}>
              <Suspense fallback={<Spin className="flex h-full w-full items-center justify-center" />}>
                <MainLayout children={<Outlet />} />
              </Suspense>
            </RequireAuth>
          }
        >
          {mainRoutes.map(({ path, element: Component }) => (
            <Route key={path} path={path} element={<Component />} />
          ))}
        </Route>
        <Route path="*" element={<Navigate to={isLogged ? routes.HOME : routes.LOGIN} replace />} />
      </Routes>
    </BrowserRouter>
  )
}
