import { ConfigProvider } from 'antd'
import { QueryClient, QueryClientProvider } from 'react-query'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { Router } from '@/router'

import { antdTheme } from './styles/antd-theme'
import { resources } from './intl/resources'
import '@/styles/global.scss'

const queryClient = new QueryClient()

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
})

export const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider theme={antdTheme}>
        <Router />
      </ConfigProvider>
    </QueryClientProvider>
  )
}
