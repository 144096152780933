import { SSE } from 'sse.js'
import { useState, useEffect, useCallback, useRef } from 'react'

import { api } from './api'
import { AUTH_PATH } from './use-service'

import { useAuthStore } from '../store/auth-store'

const BASE_URL = import.meta.env.VITE_API_BASE
const LOGOUT_RESOURCE = 'logout'

export interface ListenerEvent {
  data: any
}

export type Listeners = Record<string, (event: ListenerEvent) => void>

export const useStreamService = <P>(path: string, resource: string) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  // Map to store multiple streams and their listeners
  const streams = useRef<Map<SSE, Listeners>>(new Map())

  const handleLogoutOn401 = useCallback(async () => {
    const { logout } = useAuthStore.getState()

    try {
      await api.get(`/${AUTH_PATH}/${LOGOUT_RESOURCE}`)
    } catch (logoutError) {
      console.warn('Logout failed:', logoutError)
    }

    logout('Your session has expired. Please log in again to continue.')
  }, [])

  const closeStream = useCallback(
    (source: SSE) => {
      if (streams.current.has(source)) {
        const listeners = streams.current.get(source)!

        // Remove all listeners for this stream
        Object.entries(listeners).forEach(([key, fn]) => {
          source.removeEventListener(key, fn)
        })

        // Close the stream and remove from map
        source.close()
        streams.current.delete(source)
      }
    },
    [streams],
  )

  const invoke = useCallback(
    async (payload: P, listeners: Listeners | undefined, queryParams?: Record<string, string>) => {
      setIsLoading(true)
      setError(null)

      let url = `${BASE_URL}/${path}/${resource}`
      if (queryParams) {
        const queryString = new URLSearchParams(queryParams).toString()
        url += `?${queryString}`
      }

      let newSource: SSE | null = null

      try {
        newSource = new SSE(url, {
          headers: { 'Content-Type': 'application/json' },
          payload: JSON.stringify(payload),
          withCredentials: true,
          start: false,
        })

        // Wrapper for `open` event
        newSource.addEventListener('open', (event: ListenerEvent) => {
          if (listeners?.open) {
            listeners.open(event) // Call user's `open` if defined
          }
        })

        // Wrapper for `stream-started` event
        newSource.addEventListener('stream-start', (event: ListenerEvent) => {
          if (listeners?.['stream-start']) {
            listeners['stream-start'](event) // Call user's `stream-started` if defined
          }
        })

        // Wrapper for `stream-chunk` event
        newSource.addEventListener('stream-chunk', (event: ListenerEvent) => {
          if (listeners?.['stream-chunk']) {
            listeners['stream-chunk'](event) // Call user's `stream-chunk` if defined
          }
        })

        // Wrapper for `stream-chunk` event
        newSource.addEventListener('stream-end', (event: ListenerEvent) => {
          if (listeners?.['stream-end']) {
            listeners['stream-end'](event) // Call user's `stream-end` if defined
          }
        })

        // Wrapper for `error` event
        newSource.addEventListener('error', async (event: any) => {
          setIsLoading(false)

          // Close the source on error
          if (newSource) closeStream(newSource)

          if (listeners?.error) {
            listeners.error(event) // Call user's `error` if defined
          }

          // Check for 401 status code
          if (event?.responseCode === 401) {
            await handleLogoutOn401()
          }
        })

        // Wrapper for `close` event
        newSource.addEventListener('close', (event: ListenerEvent) => {
          setIsLoading(false)

          if (listeners?.close) {
            listeners.close(event) // Call user's `close` if defined
          }

          // Close the source explicitly on stream close
          if (newSource) closeStream(newSource)
        })

        newSource.stream()

        // Cache the new stream and its listeners
        streams.current.set(newSource, listeners!)
      } catch (err) {
        console.error('Error initializing SSE:', err)
        setError('Failed to initialize stream')
        setIsLoading(false)
      }
    },
    [closeStream, handleLogoutOn401, path, resource],
  )

  // Cleanup all streams on unmount
  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      streams.current.forEach((_, source) => {
        closeStream(source)
      })
    }
  }, [closeStream])

  return { invoke, isLoading, error, closeStream }
}
