import { create } from 'zustand'

interface AuthState {
  isLogged: boolean
  message: string
  user?: { id?: string; email?: string; name?: string }
  login: (id?: string, email?: string, name?: string) => void
  logout: (message?: string) => void
}

export const useAuthStore = create<AuthState>(set => ({
  isLogged: false,
  message: '',
  user: undefined,
  login: (id?: string, email?: string, name?: string) => set({ isLogged: true, user: { id, email, name } }),
  logout: (message?: string) => set({ isLogged: false, message: message ?? '', user: undefined }),
}))
