import { useCallback, useRef } from 'react'
import { useMutation, useQuery } from 'react-query'
import { AxiosError } from 'axios'

import { api, authenticatedApi } from './api'

interface ServiceError {
  message: string
}

export const usePostApiService = <P, R>(path: string, resource: string, withAuth = true) => {
  const mutation = useMutation<R, AxiosError<ServiceError>, P>(
    async payload => {
      const fetcher = withAuth ? authenticatedApi : api
      const url = `/${path}/${resource}`
      const res = await fetcher.post(url, payload)
      return res.data
    },
    { retry: false },
  )

  const invoke = useCallback(
    async (payload: P): Promise<{ data?: R; error?: ServiceError }> => {
      try {
        const data = await mutation.mutateAsync(payload)
        return { data, error: undefined }
      } catch (error) {
        return { data: undefined, error: (error as AxiosError<ServiceError>).response?.data }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutation.mutateAsync],
  )

  return { invoke, isLoading: mutation.isLoading, error: mutation.error?.response?.data, data: mutation.data }
}

export const useGetApiService = <P extends Record<string, any> | undefined, R>(
  path: string,
  resource: string,
  withAuth = true,
) => {
  const queryParamsRef = useRef<P | undefined>(undefined) // Ref to store the latest query params

  const queryKey = [path, resource]

  const fetcher = useCallback(async (): Promise<R> => {
    const fetchInstance = withAuth ? authenticatedApi : api
    const url = `/${path}/${resource}`
    const response = await fetchInstance.get(url, { params: queryParamsRef.current || {} })
    return response.data
  }, [path, resource, withAuth])

  const { data, error, isFetching, refetch } = useQuery<R, AxiosError<ServiceError>>(queryKey, fetcher, {
    enabled: false, // Prevent automatic fetching
    staleTime: Infinity,
    retry: false,
  })

  const invoke = useCallback(
    async (params?: P): Promise<{ data?: R; error?: ServiceError }> => {
      queryParamsRef.current = params // Update the ref with the latest query params
      try {
        const result = await refetch() // Fetch data with the latest params in the ref
        return { data: result.data, error: result.error?.response?.data }
      } catch (err) {
        return { data: undefined, error: err as ServiceError }
      }
    },
    [refetch],
  )

  return {
    invoke,
    isLoading: isFetching,
    error,
    data,
  }
}
